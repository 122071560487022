/* Mixins */
.jtl-transfer-component .ant-transfer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 500px;
}
.jtl-transfer-component .ant-transfer .ant-transfer-list {
  min-height: 500px;
}
.jtl-transfer-component .ant-transfer .ant-transfer-operation {
  padding: 40% 0 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 300px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.jtl-transfer-component .ant-transfer .ant-transfer-list-content {
  max-height: 400px;
  overflow-y: auto;
}