/* Mixins */
.check-list-component .inspection-check-list-title {
  border: 1px solid #d9d9d9;
  padding: 3px;
  font-weight: bold;
  background-color: #F5F5F5;
}
.check-list-component .table-select-inspec {
  width: 90px !important;
  min-width: 90px !important;
}
.check-list-component .inspection-list-table-row {
  height: 40px !important;
  line-height: 40px !important;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.check-list-component .inspection-list-table-row .ant-col {
  border: 0.5px solid #cdcdcd;
  border-width: 0 0 0.5px 0.5px;
  line-height: 38px;
  height: 40px;
}
.check-list-component .inspection-list-table-row .ant-col input {
  width: 96%;
  padding-left: 2%;
  border-width: 0px !important;
  height: 39px;
}
.check-list-component .inspection-list-table-row .ant-col:last-child {
  border-right: 0.5px solid #cdcdcd;
}
.check-list-component .a-label {
  color: var(--primaryColor);
}